@font-face {
    font-family: CeraPro;
    src: url("/fonts/cera/cerapro-regular.otf");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: CeraPro;
    src: url("/fonts/cera/cerapro-medium.otf");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: TTFirsNeue;
    src: url("/fonts/firsNeue/TTFirsNeue-Medium.otf");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: TTFirsNeue;
    src: url("/fonts/firsNeue/TTFirsNeue-DemiBold.otf");
    font-style: normal;
    font-weight: 600;
}
