@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";

h1,
h2,
h3,
h4 {
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 15px;
}

h1 {
    font-size: 30px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 30px;
}

h2 {
    font-size: 24px;
    font-weight: 400;
    margin: 15px 0;
}

h3 {
    font-weight: 400;
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

p {
    font-size: 17px;
}

/* Required so that React Modal animates in and out */
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
